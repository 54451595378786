import React, { Component } from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import BlogPost from '../../components/Blog/BlogPost';

class BlogPostPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false,
      authorData: null
    };
  }

  mockData = {
    allWordpressPost: {
      edges: [
        {
          node: {
            date: '2019-06-14T17:24:13.000Z',
            title: 'A Quick Guide to Optimizing Email Metrics',
            topic: [41, 31, 45, 59],
            services: [20],
            type: 'post',
            slug: 'a-quick-guide-to-optimizing-email-metrics',
            acf: {
              hero_sub_headline:
                'Insights from advanced email analytics lead to more relevant customer experiences.'
            },
            author: {
              name: 'Richard Flores',
              slug: 'rflores'
            }
          }
        },
        {
          node: {
            date: '2019-05-03T13:40:41.000Z',
            title:
              '7 Ways to Maximize the Return on Your Channel Marketing Programs',
            topic: [39, 27, 32, 59],
            services: [19],
            type: 'post',
            slug:
              '7-ways-to-maximize-the-return-on-your-channel-marketing-programs',
            acf: {
              hero_sub_headline:
                'Don’t let your co-op, MDF, or compliance programs run on autopilot. There are sales at stake.'
            },
            author: {
              name: 'Andy Arnold',
              slug: 'aarnold'
            }
          }
        },
        {
          node: {
            date: '2019-04-12T15:01:35.000Z',
            title:
              'Multichannel Orchestration Empowers Marketers to Delight Customers',
            topic: [28, 27, 99, 98, 32],
            services: [20, 21, 19],
            type: 'post',
            slug:
              'multichannel-orchestration-empowers-marketers-to-delight-customers',
            acf: {
              hero_sub_headline:
                'Brands are investing in technology to ensure seamless customer experiences.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2019-03-22T14:30:58.000Z',
            title: 'How to Succeed in the Expectation Age',
            topic: [31, 89],
            services: [20, 21, 19],
            type: 'post',
            slug: 'how-to-succeed-in-the-expectation-age',
            acf: {
              hero_sub_headline:
                'Brands must deliver outstanding experiences at every touchpoint — or risk losing customers forever.'
            },
            author: {
              name: 'Andy Arnold',
              slug: 'aarnold'
            }
          }
        },
        {
          node: {
            date: '2019-03-20T16:25:16.000Z',
            title:
              'Brand-to-Local Marketing: Exceed Customer Expectations in a Complex Ecosystem',
            topic: [28, 26],
            services: [20, 21, 19],
            type: 'post',
            slug: 'brand2local-marketing',
            acf: {
              hero_sub_headline:
                'When brands and their channel partners are in sync, customer experiences become seamless. '
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2019-03-01T21:58:01.000Z',
            title: 'Why Channel Marketing Requires a Brand-to-Local Approach',
            topic: [28, 27],
            services: [19],
            type: 'post',
            slug: 'why-channel-marketing-requires-a-brand2local-approach',
            acf: {
              hero_sub_headline:
                'Brands that meet the expectations of their channel partners and their customers will win market share.'
            },
            author: {
              name: 'Adam Vandermyde',
              slug: 'avandermyde'
            }
          }
        },
        {
          node: {
            date: '2019-01-20T04:37:29.000Z',
            title: 'The New Role of Tech Channel Partners',
            topic: [39, 28, 27, 32],
            services: [19],
            type: 'post',
            slug: 'new-role-of-tech-channel-partners',
            acf: {
              hero_sub_headline:
                'Resellers must be properly trained and take a more consultative approach with clients.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2019-01-18T18:30:51.000Z',
            title: 'The Benefits of Bots',
            topic: [47, 32],
            services: [20],
            type: 'post',
            slug: 'benefits-of-bots',
            acf: {
              hero_sub_headline:
                'Responsive AI technology delivers innovative customer experiences. '
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        },
        {
          node: {
            date: '2019-01-11T00:45:47.000Z',
            title: '4 Ways to Better Connect With Hispanic Consumers',
            topic: [31, 24, 26, 59],
            services: [20],
            type: 'post',
            slug: '4-ways-to-better-connect-with-hispanic-consumers',
            acf: {
              hero_sub_headline:
                'There is $1.4 trillion in spending power at stake.'
            },
            author: {
              name: 'Trinidad Aguirre',
              slug: 'taguirre'
            }
          }
        },
        {
          node: {
            date: '2018-12-19T09:03:51.000Z',
            title:
              'Want to Boost In-Store Purchases? Get Your Local Listings in Order.',
            topic: [28, 31, 29, 30, 59],
            services: [21],
            type: 'post',
            slug:
              'want-to-boost-in-store-purchases-get-your-local-listings-in-order',
            acf: {
              hero_sub_headline:
                'In-store buys are heavily influenced by local search efforts.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-12-13T03:19:55.000Z',
            title: '9 Trends for 2019 That QSR Brands Can’t Ignore',
            topic: [31, 32, 33],
            services: [20],
            type: 'post',
            slug: '9-trends-for-2019-qsr-brands-cant-ignore',
            acf: {
              hero_sub_headline:
                'To stay competitive, QSRs must close the gap between what they serve and what customers expect.'
            },
            author: {
              name: 'Austin Wright',
              slug: 'awright'
            }
          }
        },
        {
          node: {
            date: '2018-11-29T03:32:38.000Z',
            title: '3 Ways Automotive Brands Cause Customers to Hit the Brakes',
            topic: [28, 31, 36, 59, 35],
            services: [20],
            type: 'post',
            slug: '3-ways-automotive-brands-cause-customers-to-hit-the-brakes',
            acf: {
              hero_sub_headline:
                'Speed past these online mistakes for a frictionless customer experience.'
            },
            author: {
              name: 'Rachel Maynard',
              slug: 'rmaynard'
            }
          }
        },
        {
          node: {
            date: '2018-11-15T09:39:55.000Z',
            title: 'How to Power Up Your Sales Force',
            topic: [27, 44, 38, 32, 59],
            services: [19],
            type: 'post',
            slug: 'how-to-power-up-your-sales-force',
            acf: {
              hero_sub_headline:
                'Arm your front line with the right technology and content and watch the sales roll in.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-11-13T09:49:42.000Z',
            title: 'Why Every Brand Must Be an Experience-Driven Business',
            topic: [31, 32],
            services: [20],
            type: 'post',
            slug: 'why-every-brand-must-be-an-experience-driven-business',
            acf: {
              hero_sub_headline:
                'Forget price or quality. Customer experience is the best brand differentiator.'
            },
            author: {
              name: 'Gilbert Salazar',
              slug: 'gsalazar'
            }
          }
        },
        {
          node: {
            date: '2018-11-07T09:57:06.000Z',
            title: '5 Loyalty Program Challenges — and How to Overcome Them',
            topic: [40, 26, 59],
            services: [20],
            type: 'post',
            slug: '5-loyalty-program-challenges-and-how-to-overcome-them',
            acf: {
              hero_sub_headline:
                'Rev up your loyalty program and keep customers coming back.'
            },
            author: {
              name: 'Mike Bradley',
              slug: 'mbradley'
            }
          }
        },
        {
          node: {
            date: '2018-10-30T09:06:48.000Z',
            title: 'The Urgency of Multicultural Marketing',
            topic: [24, 26],
            services: [20, 21],
            type: 'post',
            slug: 'the-urgency-of-multicultural-marketing',
            acf: {
              hero_sub_headline:
                'Multicultural groups represent $4.3 trillion in consumer buying power. Welcome to the new super consumers.'
            },
            author: {
              name: 'Trinidad Aguirre',
              slug: 'taguirre'
            }
          }
        },
        {
          node: {
            date: '2018-10-09T09:18:48.000Z',
            title: 'The Modern Approach to Captivating Customers',
            topic: [28, 27, 41, 31, 32],
            services: [20, 19],
            type: 'post',
            slug: 'the-modern-approach-to-captivating-customers',
            acf: {
              hero_sub_headline:
                'How engagement marketing has evolved — and what you can do about it.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-09-28T04:39:50.000Z',
            title: 'How to Solve 6 Common Channel Partner Marketing Challenges',
            topic: [39, 28, 27, 32, 59],
            services: [19],
            type: 'post',
            slug: 'solve-6-common-channel-partner-marketing-challenges',
            acf: {
              hero_sub_headline:
                'Marketing automation technology helps brands streamline marketing at the local level.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-09-20T09:48:31.000Z',
            title: '12 Reasons Why Your Content Marketing Is Doomed to Fail',
            topic: [44, 26, 59],
            services: [20],
            type: 'post',
            slug: '12-reasons-why-your-content-marketing-is-doomed-to-fail',
            acf: {
              hero_sub_headline:
                'You know you need content as part of your marketing strategy. Just don’t do these things.'
            },
            author: {
              name: 'Jennifer Chininis',
              slug: 'jchininis'
            }
          }
        },
        {
          node: {
            date: '2018-09-17T16:16:56.000Z',
            title: 'What Channel Partners Should Ask Their National Brand',
            topic: [39, 28, 27, 32, 59],
            services: [19],
            type: 'post',
            slug: 'what-channel-partners-should-ask-their-national-brand',
            acf: {
              hero_sub_headline:
                'To maximize local marketing efforts, channel partners need to speak up.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-09-12T21:24:38.000Z',
            title: 'Is Your Email Marketing Annoying Customers?',
            topic: [31, 45, 59],
            services: [20],
            type: 'post',
            slug: 'is-your-email-marketing-annoying-customers',
            acf: {
              hero_sub_headline:
                'Follow these tips to optimize email deliverability — and steer clear of spam.'
            },
            author: {
              name: 'Tamara Mullin',
              slug: 'tmullin'
            }
          }
        },
        {
          node: {
            date: '2018-09-10T06:32:36.000Z',
            title:
              'An SEO Content Checklist for Mastering Your Marketing Messages',
            topic: [44, 30, 59],
            services: [21],
            type: 'post',
            slug: 'seo-content-checklist-for-mastering-your-marketing-messages',
            acf: {
              hero_sub_headline:
                'Follow these SEO best practices to make the greatest impact.'
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        },
        {
          node: {
            date: '2018-08-30T06:40:45.000Z',
            title: '6 Metrics for Measuring Loyalty Program Success',
            topic: [46, 40, 26, 59],
            services: [20],
            type: 'post',
            slug: '6-metrics-for-measuring-loyalty-program-success',
            acf: {
              hero_sub_headline:
                'Refining your loyalty program based on data-driven insight is the fastest path to ROI.'
            },
            author: {
              name: 'Mike Bradley',
              slug: 'mbradley'
            }
          }
        },
        {
          node: {
            date: '2018-08-28T06:40:59.000Z',
            title:
              'Customer Experience or Human Experience: What’s More Important in Marketing?',
            topic: [47, 31, 32, 33],
            services: [20],
            type: 'post',
            slug:
              'customer-experience-or-human-experience-whats-more-important-in-marketing',
            acf: {
              hero_sub_headline:
                'Brands that treat customers as people may be best positioned for long-term loyalty.'
            },
            author: {
              name: 'Jess Hilton',
              slug: 'jhilton'
            }
          }
        },
        {
          node: {
            date: '2018-08-23T06:57:40.000Z',
            title: 'Content Audit: The Content Marketer’s Best Friend',
            topic: [44, 26],
            services: [20],
            type: 'post',
            slug: 'content-audit-the-content-marketers-best-friend',
            acf: {
              hero_sub_headline:
                'This comprehensive review informs the brand’s content marketing strategy for optimal results.'
            },
            author: {
              name: 'Julie Kinzie',
              slug: 'jkinzie'
            }
          }
        },
        {
          node: {
            date: '2018-08-13T02:01:06.000Z',
            title: '3 Different Approaches to Co-op Advertising',
            topic: [27, 48],
            services: [19],
            type: 'post',
            slug: 'three-different-approaches-to-co-op-advertising',
            acf: {
              hero_sub_headline:
                'A look at the best practices designed to ensure smart spending.'
            },
            author: {
              name: 'Dan Gower',
              slug: 'dgower'
            }
          }
        },
        {
          node: {
            date: '2018-07-31T22:31:09.000Z',
            title:
              'Digital Media Hasn’t Crushed Traditional. But the Power Has Shifted.',
            topic: [69, 70, 33],
            services: [21],
            type: 'post',
            slug:
              'digital-media-hasnt-crushed-traditional-but-the-power-has-shifted',
            acf: {
              hero_sub_headline:
                'Is your brand meeting consumers where they are?'
            },
            author: {
              name: 'Dan Gower',
              slug: 'dgower'
            }
          }
        },
        {
          node: {
            date: '2018-07-25T17:43:28.000Z',
            title:
              'Blockchain Startups Offer Promise for the Marketing Industry',
            topic: [71, 33],
            services: [20],
            type: 'post',
            slug:
              'blockchain-startups-offer-promise-for-the-marketing-industry',
            acf: {
              hero_sub_headline:
                'New companies are emerging, but will they be around for the long term?'
            },
            author: {
              name: 'Logan Flatt',
              slug: 'lflatt'
            }
          }
        },
        {
          node: {
            date: '2018-07-20T17:34:36.000Z',
            title: 'The Sleeping Ad Giant',
            topic: [69, 54, 58, 32],
            services: [21],
            type: 'post',
            slug: 'the-sleeping-ad-giant',
            acf: {
              hero_sub_headline:
                'Will AT&T’s acquisition of AppNexus be a significant disruptor in the digital ad space?'
            },
            author: {
              name: 'Ben Brown',
              slug: 'bbrown'
            }
          }
        },
        {
          node: {
            date: '2018-07-18T21:45:23.000Z',
            title: 'Attribution: A New Mandate for Marketers',
            topic: [68, 38, 32],
            services: [20],
            type: 'post',
            slug: 'attribution-new-mandate-for-marketers',
            acf: {
              hero_sub_headline:
                'The marketer’s role is changing to include accountability for the bottom line.'
            },
            author: {
              name: 'Katie Freeman',
              slug: 'kfreeman'
            }
          }
        },
        {
          node: {
            date: '2018-07-16T16:15:08.000Z',
            title:
              'Omnichannel Orchestration: The Gold Standard of Engagement Marketing',
            topic: [41, 31, 67, 26, 32],
            services: [20],
            type: 'post',
            slug:
              'omnichannel-orchestration-gold-standard-engagement-marketing',
            acf: {
              hero_sub_headline:
                'How to influence customers in the buying moment.'
            },
            author: {
              name: 'Trae Clevenger',
              slug: 'tclevenger'
            }
          }
        },
        {
          node: {
            date: '2018-07-13T16:02:41.000Z',
            title: '7 Biggest Mistakes Brands Make With Social Media',
            topic: [53, 26, 59],
            services: [21],
            type: 'post',
            slug: '7-biggest-mistakes-brands-make-with-social-media',
            acf: {
              hero_sub_headline:
                'Leveraging social platforms to engage customers is extremely important — but only if done correctly.'
            },
            author: {
              name: 'Sarah Hudnall',
              slug: 'shudnall'
            }
          }
        },
        {
          node: {
            date: '2018-07-05T15:48:06.000Z',
            title: '5 Steps to a Successful Data-Driven Marketing Strategy',
            topic: [41, 46, 56, 26, 32],
            services: [20],
            type: 'post',
            slug: '5-steps-successful-data-driven-marketing-strategy',
            acf: {
              hero_sub_headline:
                'A comprehensive approach to data analysis empowers brands to accurately market to their customers.'
            },
            author: {
              name: 'Trae Clevenger',
              slug: 'tclevenger'
            }
          }
        },
        {
          node: {
            date: '2018-06-28T02:48:11.000Z',
            title:
              'You Want the Real Truth About Your Customers? Look at the Transactional Data.',
            topic: [41, 46, 56, 26],
            services: [20],
            type: 'post',
            slug: 'real-truth-about-your-customers-look-at-transactional-data',
            acf: {
              hero_sub_headline:
                'Market research can be deceiving. Buying behavior isn’t.'
            },
            author: {
              name: 'Jess Hilton',
              slug: 'jhilton'
            }
          }
        },
        {
          node: {
            date: '2018-06-26T20:22:24.000Z',
            title:
              'Instagram vs. YouTube: The Ongoing Battle for Video Dominance',
            topic: [66, 63, 53, 65, 64],
            services: [21],
            type: 'post',
            slug: 'instagram-vs-youtube-ongoing-battle-video-dominance',
            acf: {
              hero_sub_headline:
                'The rivalry between the two social networks heats up as Instagram pushes video limits.'
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        },
        {
          node: {
            date: '2018-06-22T04:25:13.000Z',
            title: '8 Best Practices for Improving the Email User Experience',
            topic: [45, 59, 35],
            services: [20],
            type: 'post',
            slug: '8-best-practices-for-improving-email-user-experience',
            acf: {
              hero_sub_headline:
                'Email is here to stay. Marketers need to make theirs memorable — and user-friendly.'
            },
            author: {
              name: 'Rachel Maynard',
              slug: 'rmaynard'
            }
          }
        },
        {
          node: {
            date: '2018-06-19T20:10:26.000Z',
            title: 'What GDPR Means for Brands',
            topic: [46, 62],
            services: [20],
            type: 'post',
            slug: 'what-gdpr-means-for-brands',
            acf: {
              hero_sub_headline:
                'Data collection doesn’t change. Making sure users are okay with it does.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-06-11T09:22:20.000Z',
            title: '5 Ways to Boost Local Marketing Efforts With Google Posts',
            topic: [28, 58, 29, 30],
            services: [21],
            type: 'post',
            slug: '5-ways-to-boost-local-marketing-efforts-with-google-posts',
            acf: {
              hero_sub_headline:
                'This tool can drive engagement, awareness, store visits, and sales — and it’s free.'
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        },
        {
          node: {
            date: '2018-06-08T04:10:11.000Z',
            title: 'Can Creative and Data Peacefully Coexist?',
            topic: [57, 46],
            services: [20],
            type: 'post',
            slug: 'can-creative-and-data-peacefully-coexist',
            acf: {
              hero_sub_headline: 'Short answer: yes. Here’s why.'
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        },
        {
          node: {
            date: '2018-05-22T08:59:10.000Z',
            title: '4 Proven Brand-to-Local Marketing Tips',
            topic: [28, 27, 32],
            services: [19],
            type: 'post',
            slug: '4-proven-brand-to-local-marketing-tips',
            acf: {
              hero_sub_headline:
                'With the right marketing tools, channel partners can deliver enhanced customer experiences and drive sales.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-05-21T03:49:26.000Z',
            title:
              'How Marketers Can Make Smart Use of Artificial Intelligence',
            topic: [51, 32],
            services: [20],
            type: 'post',
            slug: 'how-marketers-can-make-smart-use-of-artificial-intelligence',
            acf: {
              hero_sub_headline:
                'Beyond the confusion and hype is a technology that can change the marketing game.'
            },
            author: {
              name: 'Trae Clevenger',
              slug: 'tclevenger'
            }
          }
        },
        {
          node: {
            date: '2018-05-18T08:22:40.000Z',
            title: 'How Brands Empower Channel Partners to Market Locally',
            topic: [39, 28, 27, 32],
            services: [19],
            type: 'post',
            slug: 'how-brands-empower-channel-partners-to-market-locally',
            acf: {
              hero_sub_headline:
                'Real-time intelligence tool streamlines market funding to better manage local marketing efforts.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2018-05-17T03:01:46.000Z',
            title: '4 Ways to Build Brand Power',
            topic: [31, 26],
            services: [20],
            type: 'post',
            slug: '4-ways-to-build-brand-power',
            acf: {
              hero_sub_headline:
                'How do iconic brands earn that status? By following this road map.'
            },
            author: {
              name: 'Austin Wright',
              slug: 'awright'
            }
          }
        },
        {
          node: {
            date: '2018-05-15T02:36:56.000Z',
            title: 'How Domino’s Achieved World Pizza Domination',
            topic: [31, 55, 26],
            services: [20],
            type: 'post',
            slug: 'dominos-world-pizza-domination',
            acf: {
              hero_sub_headline:
                'The four key drivers to the brand’s off-the-chart success.'
            },
            author: {
              name: 'Austin Wright',
              slug: 'awright'
            }
          }
        },
        {
          node: {
            date: '2018-04-13T02:31:06.000Z',
            title:
              'Cambridge Analytica Scandal: Should Brands Hit Pause on Facebook Ads?',
            topic: [69, 54, 53],
            services: [21],
            type: 'post',
            slug:
              'cambridge-analytica-scandal-should-brands-hit-pause-on-facebook-ads',
            acf: {
              hero_sub_headline:
                'We dug into the data to see if the controversy has negatively impacted social campaigns.'
            },
            author: {
              name: 'Augusta Rizzardini',
              slug: 'arizzardini'
            }
          }
        },
        {
          node: {
            date: '2018-03-25T07:25:02.000Z',
            title:
              'Savvy Retailers Leverage Technology to Influence the Customer Journey',
            topic: [51, 49, 31, 52, 32],
            services: [20],
            type: 'post',
            slug:
              'retailers-leverage-technology-to-influence-the-customer-journey',
            acf: {
              hero_sub_headline:
                'Augmented reality, artificial intelligence, and experiential marketing drive positive customer experiences — and revenue.'
            },
            author: {
              name: 'Austin Wright',
              slug: 'awright'
            }
          }
        },
        {
          node: {
            date: '2018-03-05T02:08:44.000Z',
            title: 'Why Content Matters in Retail Marketing',
            topic: [44, 31],
            services: [],
            type: 'post',
            slug: 'why-content-matters-in-retail-marketing',
            acf: {
              hero_sub_headline:
                'Meaningful content promotes positive customer experiences — and drives sales.'
            },
            author: {
              name: 'Julie Kinzie',
              slug: 'jkinzie'
            }
          }
        },
        {
          node: {
            date: '2017-11-30T07:13:31.000Z',
            title: 'How to Solve 5 Channel Marketing Problems With 1 Tool',
            topic: [39, 28, 27, 32, 59],
            services: [19],
            type: 'post',
            slug: 'how-to-solve-5-channel-marketing-problems-with-1-tool',
            acf: {
              hero_sub_headline:
                'Enable consistent multichannel marketing for your brand and its distribution partners.'
            },
            author: {
              name: 'Courtney Acuff',
              slug: 'cacuff'
            }
          }
        },
        {
          node: {
            date: '2017-05-06T00:41:54.000Z',
            title: 'Beacons: An Emerging Tool for Precise Location Marketing',
            topic: [95, 28, 29, 32],
            services: [20],
            type: 'post',
            slug: 'beacons-an-emerging-tool-for-precise-location-marketing',
            acf: {
              hero_sub_headline:
                'With this technology, brands can communicate with customers in the moment.'
            },
            author: {
              name: 'David Pierpont',
              slug: 'dpierpont'
            }
          }
        }
      ]
    },
    allWordpressWpServices: {
      edges: [
        {
          node: {
            wordpress_id: 20,
            slug: 'crm-loyalty-marketing',
            name: 'CRM and Loyalty Marketing'
          }
        },
        {
          node: {
            wordpress_id: 21,
            slug: 'national-local-media',
            name: 'National and Local Media'
          }
        },
        {
          node: {
            wordpress_id: 19,
            slug: 'through-channel-marketing-automation',
            name: 'Through-Channel Marketing Automation'
          }
        }
      ]
    },
    allWordpressWpTopic: {
      edges: [
        {
          node: {
            id: '5eed0a96-e356-576b-b8f0-866d968cd23e',
            name: 'Acquisitions',
            slug: 'acquisitions',
            description: '',
            wordpress_id: 87
          }
        },
        {
          node: {
            id: '9d2c4fe6-924d-5586-b861-9b3fb471b25b',
            name: 'Adobe',
            slug: 'adobe',
            description: '',
            wordpress_id: 85
          }
        },
        {
          node: {
            id: 'ebd4d001-ee42-5da2-b6e9-030c34efaa37',
            name: 'Ansira Edge',
            slug: 'ansira-edge',
            description: '',
            wordpress_id: 39
          }
        },
        {
          node: {
            id: '44cebf21-5121-593b-9ad1-815f4a7c850e',
            name: 'Artificial Intelligence',
            slug: 'artificial-intelligence',
            description: '',
            wordpress_id: 51
          }
        },
        {
          node: {
            id: 'f0786393-4648-5a1e-932f-f9ce31e5d234',
            name: 'Augmented Reality',
            slug: 'augmented-reality',
            description: '',
            wordpress_id: 49
          }
        },
        {
          node: {
            id: 'ffd09e20-4696-50b4-9629-0a6dde144df2',
            name: 'Beacons',
            slug: 'beacons',
            description: '',
            wordpress_id: 95
          }
        },
        {
          node: {
            id: '38c3a295-b6c8-5101-a12b-fecb7e1a6e54',
            name: 'Blockchain',
            slug: 'blockchain',
            description: '',
            wordpress_id: 71
          }
        },
        {
          node: {
            id: 'c81e6123-b516-57d2-9fb6-9ef8901a026d',
            name: 'Bots',
            slug: 'bots',
            description: '',
            wordpress_id: 47
          }
        },
        {
          node: {
            id: '83fc7388-6e6c-5ae9-b9d1-d22c1fd8ebe8',
            name: 'Brand-to-Local',
            slug: 'brand-to-local',
            description: '',
            wordpress_id: 28
          }
        },
        {
          node: {
            id: '273298df-0ab0-58a6-9377-90bd4678dbe6',
            name: 'Case Study',
            slug: 'case-study',
            description: '',
            wordpress_id: 81
          }
        },
        {
          node: {
            id: '10195832-5fae-5bff-9a76-66680e7d493d',
            name: 'Channel Marketing',
            slug: 'channel-marketing',
            description: '',
            wordpress_id: 27
          }
        },
        {
          node: {
            id: '16a2ad37-1176-5919-84fe-16632714a37a',
            name: 'Co-op Advertising',
            slug: 'co-op-advertising',
            description: '',
            wordpress_id: 48
          }
        },
        {
          node: {
            id: 'da9a3736-1f8f-5a28-96c1-475e12123bd4',
            name: 'Content',
            slug: 'content',
            description: '',
            wordpress_id: 44
          }
        },
        {
          node: {
            id: '15443bf5-69d6-5aee-b56c-e7c19112295f',
            name: 'Creative',
            slug: 'creative',
            description: '',
            wordpress_id: 57
          }
        },
        {
          node: {
            id: '28046911-1ac7-511a-ac8f-8302cd3ad351',
            name: 'CRM',
            slug: 'crm',
            description: '',
            wordpress_id: 41
          }
        },
        {
          node: {
            id: '88e9a119-34eb-5506-b098-2b3989603cef',
            name: 'Customer Experience',
            slug: 'customer-experience',
            description: '',
            wordpress_id: 31
          }
        },
        {
          node: {
            id: '38492f0b-c11a-5ad5-8c9a-707770c2f7c9',
            name: 'Data',
            slug: 'data',
            description: '',
            wordpress_id: 46
          }
        },
        {
          node: {
            id: '7641cfca-640b-57d4-960e-19a897d60eee',
            name: 'Digital Advertising',
            slug: 'digital-advertising',
            description: '',
            wordpress_id: 69
          }
        },
        {
          node: {
            id: 'a53ff7ff-2db5-5a4f-b777-302e2c25d5fd',
            name: 'E-Commerce',
            slug: 'e-commerce',
            description: '',
            wordpress_id: 55
          }
        },
        {
          node: {
            id: 'c68dec1d-6c58-5133-aafb-306aa75e985d',
            name: 'Email',
            slug: 'email',
            description: '',
            wordpress_id: 45
          }
        },
        {
          node: {
            id: '96e59d25-4216-5474-91cf-52e9834e1a0d',
            name: 'Expectation Age',
            slug: 'expectation-age',
            description: '',
            wordpress_id: 89
          }
        },
        {
          node: {
            id: '3f4b991d-1369-5b89-8e59-457de2a39e65',
            name: 'Experiential Marketing',
            slug: 'experiential-marketing',
            description: '',
            wordpress_id: 52
          }
        },
        {
          node: {
            id: '9e33b724-a0b3-596b-94f0-908cd21f2595',
            name: 'Facebook',
            slug: 'facebook',
            description: '',
            wordpress_id: 54
          }
        },
        {
          node: {
            id: '8b06783f-e07f-5ffa-9e78-64b7da88d8e0',
            name: 'Forrester',
            slug: 'forrester',
            description: '',
            wordpress_id: 84
          }
        },
        {
          node: {
            id: 'f67aa9ef-a738-5041-8059-d5055a8998cf',
            name: 'Google',
            slug: 'google',
            description: '',
            wordpress_id: 58
          }
        },
        {
          node: {
            id: '227b01f4-22f8-5169-8941-222ac6286fb8',
            name: 'IGTV',
            slug: 'igtv',
            description: '',
            wordpress_id: 66
          }
        },
        {
          node: {
            id: '15626358-a182-5ee7-903a-dcb7921528b6',
            name: 'Infographic',
            slug: 'infographic',
            description: '',
            wordpress_id: 99
          }
        },
        {
          node: {
            id: 'd1792e57-1b9d-56a7-8a6a-710433f7c4e3',
            name: 'Instagram',
            slug: 'instagram',
            description: '',
            wordpress_id: 63
          }
        },
        {
          node: {
            id: 'e4a6663b-5a64-580a-b921-86d3cf8c4401',
            name: 'Leadership',
            slug: 'leadership',
            description: '',
            wordpress_id: 88
          }
        },
        {
          node: {
            id: '8f029284-eb0d-5861-8d43-3c217fb44e3d',
            name: 'Local Marketing',
            slug: 'local-marketing',
            description: '',
            wordpress_id: 29
          }
        },
        {
          node: {
            id: '199f4c23-c345-5db6-a7a3-82c2060ce475',
            name: 'Loyalty',
            slug: 'loyalty',
            description: '',
            wordpress_id: 40
          }
        },
        {
          node: {
            id: '6597ec28-374a-5ee3-9519-8443804b6c4c',
            name: 'Marketing Attribution',
            slug: 'marketing-attribution',
            description: '',
            wordpress_id: 68
          }
        },
        {
          node: {
            id: 'c2c0cd96-1494-5fd1-bb4e-933a8f62a1b4',
            name: 'Marketing Science',
            slug: 'marketing-science',
            description: '',
            wordpress_id: 56
          }
        },
        {
          node: {
            id: '8f45e426-28e8-5dd4-8677-c69151af47b1',
            name: 'Mobile',
            slug: 'mobile',
            description: '',
            wordpress_id: 36
          }
        },
        {
          node: {
            id: 'e879aece-bde9-5fbf-9636-a6937c02cbe0',
            name: 'Multicultural Marketing',
            slug: 'multicultural-marketing',
            description: '',
            wordpress_id: 24
          }
        },
        {
          node: {
            id: '422e903b-90c1-5fea-bc8a-e595fddf9e1e',
            name: 'Omnichannel Orchestration',
            slug: 'omnichannel-orchestration',
            description: '',
            wordpress_id: 67
          }
        },
        {
          node: {
            id: '106e6625-4148-563c-a9c9-72c48a7ae58e',
            name: 'Paid Search',
            slug: 'paid-search',
            description: '',
            wordpress_id: 101
          }
        },
        {
          node: {
            id: 'df534dca-41da-5f84-bcac-c7292957e621',
            name: 'Partnerships',
            slug: 'partnerships',
            description: '',
            wordpress_id: 86
          }
        },
        {
          node: {
            id: '9825f37a-5b71-51f9-ba23-c2f07f7e0347',
            name: 'Privacy',
            slug: 'privacy',
            description: '',
            wordpress_id: 62
          }
        },
        {
          node: {
            id: 'f0c51aa5-8966-54dd-9d49-f719e08e2fd3',
            name: 'Recognition',
            slug: 'recognition',
            description: '',
            wordpress_id: 83
          }
        },
        {
          node: {
            id: 'c3f13d87-e616-57c0-a9c4-730ba73767d3',
            name: 'Research',
            slug: 'research',
            description: '',
            wordpress_id: 98
          }
        },
        {
          node: {
            id: '629ffc2e-d9ce-5938-83ad-c88a1d4b1386',
            name: 'Sales',
            slug: 'sales',
            description: '',
            wordpress_id: 38
          }
        },
        {
          node: {
            id: 'c59c7718-5dad-5b0f-bf05-8bb2cc45c747',
            name: 'SEM',
            slug: 'sem',
            description: '',
            wordpress_id: 82
          }
        },
        {
          node: {
            id: '199dfb21-ac97-55dd-aeae-888a8a7d2c4b',
            name: 'SEO',
            slug: 'seo',
            description: '',
            wordpress_id: 30
          }
        },
        {
          node: {
            id: '35242419-558e-5e14-97ac-c377f989dda2',
            name: 'Social Media',
            slug: 'social-media',
            description: '',
            wordpress_id: 53
          }
        },
        {
          node: {
            id: '172b6f9b-c28d-5718-90ee-c65dc933e923',
            name: 'Specializations',
            slug: 'specializations',
            description: '',
            wordpress_id: 97
          }
        },
        {
          node: {
            id: 'eb237a33-d358-5345-8e55-7b109c8684bb',
            name: 'Sponsorships',
            slug: 'sponsorships',
            description: '',
            wordpress_id: 90
          }
        },
        {
          node: {
            id: '12fd9c6a-7069-5434-80cc-1462a3f532ee',
            name: 'Strategy',
            slug: 'strategy',
            description: '',
            wordpress_id: 26
          }
        },
        {
          node: {
            id: 'd4b9f4b7-3f17-5006-8388-71d5e058e242',
            name: 'Technology',
            slug: 'technology',
            description: '',
            wordpress_id: 32
          }
        },
        {
          node: {
            id: '91ae65ec-8b4b-5208-86aa-94d32e0d5926',
            name: 'Tips',
            slug: 'tips',
            description: '',
            wordpress_id: 59
          }
        },
        {
          node: {
            id: '33ab256f-1d38-5399-a377-58d34846de62',
            name: 'Traditional Advertising',
            slug: 'traditional-advertising',
            description: '',
            wordpress_id: 70
          }
        },
        {
          node: {
            id: '1d71693f-1309-5962-b97a-6671fc3ef424',
            name: 'Trends',
            slug: 'trends',
            description: '',
            wordpress_id: 33
          }
        },
        {
          node: {
            id: '786d8d1f-f49f-589a-af69-e9b87b966970',
            name: 'UX',
            slug: 'ux',
            description: '',
            wordpress_id: 35
          }
        },
        {
          node: {
            id: '87c43a48-7c49-53b6-b92d-380dfc463e87',
            name: 'Video',
            slug: 'video',
            description: '',
            wordpress_id: 65
          }
        },
        {
          node: {
            id: '4bb5c854-6c1a-5a77-8b73-1bde48be4d9e',
            name: 'YouTube',
            slug: 'youtube',
            description: '',
            wordpress_id: 64
          }
        }
      ]
    }
  };

  // post = this.props.data.wordpressPost;
  posts = this.mockData.allWordpressPost;
  tags = this.mockData.allWordpressWpTopic;
  // allServices = this.props.data.allWordpressWpOurSolutions ? this.props.data.allWordpressWpOurSolutions : '';
  // servicesMap = this.props.data.allWordpressWpOurSolutions ? this.props.data.allWordpressWpOurSolutions.edges.node : '';

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      //let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/v1/preview/`;
      fetch(`${BASE_URL}${id}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson
            });
            // get author data
            this.getAuthorData(myJson.post_author);
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  getAuthorData(id) {
    let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2/users/`;
    fetch(`${BASE_URL}${id}`, {
      mode: 'cors',
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        if (myJson) {
          this.setState({
            authorData: myJson
          });
        }
      });
  }

  render() {
    const post = this.state.previewPayload;
    const author = this.state.authorData;

    if (post) {
      post.date = post.acf ? post.acf.display_date : '01/08/2019"';
      post.title = post.post_title;
      post.content = post.post_content;
      post.author = {};
      post.author = {
        name: 'Bryan Baker',
        acf: {
          shortbio: 'I like to try new things.',
          author_title: 'Director',
          author_photo: {
            source_url: 'https://via.placeholder.com/200?text=Author+Photo'
          }
        }
      };
      post.better_featured_image = {
        source_url:
          'https://via.placeholder.com/1500?text=Featured+Image+Not+Previewable'
      };
      post.topic = [28, 27];
    }

    if (author) {
      post.author = {
        name: author.name,
        acf: {
          shortbio: author.acf.shortbio,
          author_title: author.acf.author_title,
          author_photo: {
            source_url: author.acf.author_photo.url
          }
        }
      };
    }

    return post ? (
      <BlogPost
        post={post}
        posts={this.posts}
        tags={this.tags}
        servicesMap={this.servicesMap}
        allServices={this.allServices}
        isPreviewMode={this.state.isPreviewMode}
      />
    ) : null;
  }
}

export default BlogPostPreview;
